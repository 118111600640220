<template>
  <div :class="isSP ? 'inspection--sp' : 'inspection--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="inspection__visual--sp" :img="require('@/assets/img/visual/inspection_sp.jpeg')" title="車検・整備・修理" />
    <pc-visual v-else class="inspection__visual--pc" :img="require('@/assets/img/visual/inspection_pc.jpeg')" title="車検・整備・修理" />
    <!-- タイトル -->
    <h1 v-if="isSP" class="inspection__title--sp">車検・整備・修理</h1>
    <h1 v-else class="inspection__title--pc">車検・整備・修理</h1>
    <!-- 詳細情報 -->
    <sp-description v-if="isSP" class="inspection__description--sp" :main="description.main" :sub="description.sub" />
    <pc-description v-else class="inspection__description--pc" :main="description.main" :sub="description.sub" />
    <!-- 画像集 -->
    <v-img v-if="isSP" class="inspection__images--sp" :src="require('@/assets/img/inspection/image1_sp.jpeg')" :aspect-ratio="293/605"  cover />
    <v-img v-else class="inspection__images--pc" :src="require('@/assets/img/inspection/image1_pc.jpeg')" :aspect-ratio="1921/1345"  cover />
    <!-- スライドショー -->
    <sp-slideshow v-if="isSP" class="inspection__slideshow--sp" :imgs="slideshows" />
    <v-img v-else class="inspection__slideshow--pc" :src="require('@/assets/img/inspection/slideshow_pc.jpeg')" :aspect-ratio="437/1921"  cover />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="false" />
    <pc-contact v-else :isRecruit="false" />
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpDescription from '@/components/common/description/sp'
import PcDescription from '@/components/common/description/pc'
import SpSlideshow from '@/components/common/slideshow/sp'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'inspection',
  components: { SpVisual, PcVisual, SpDescription, PcDescription, SpSlideshow, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {Object} 説明文
     */
    description () {
      return {
        main: '九州運輸局指定の民間車検工場です。\n軽自動車をはじめ、専門知識が必要な大型トラックや大型特殊車・特装車まで、あらゆる車種に対応可能。\n定期点検・特定自主検査も行っており現地での出張検査・点検も可能です。\n\nシンエイの整備士全員が国が認めた国家資格所有者です。安心してお任せください。',
        sub: '※ 弊社は日野自動車指定サービス工場です。'
      }
    },
    /**
     * @return {String[]} スライドショーの画像一覧
     */
    slideshows () {
      const number = 5
      return [...Array(number)].map((_, index) => require('@/assets/img/inspection/slideshow' + (index + 1) + '.jpeg'))
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.inspection {
  width: 100%;
  &--sp {
    @extend .inspection;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .inspection;
    margin-top: $pc_header_height;
  }
  &__title {
    font-weight: 500;
    text-align: center;
    &--sp {
      @extend .inspection__title;
      margin-top: 70px;
      font-size: 1.5rem;
    }
    &--pc {
      @extend .inspection__title;
      margin-top: 171px;
      font-size: 3.5rem;
    }
  }
  &__description {
    &--sp {
      @extend .inspection__description;
      margin-top: 55px;
    }
    &--pc {
      @extend .inspection__description;
      margin-top: 100px;
    }
  }
  &__images {
    &--sp {
      @extend .inspection__images;
      display: block;
      width: 293px;
      max-width: 80vw;
      height: 605px;
      max-height: 165.1877vw;
      margin: 60px auto 0 auto;
    }
    &--pc {
      @extend .inspection__images;
      display: block;
      width: 100vw;
      height: 70.0156vw;
      margin: 79px auto 0 auto;
    }
  }
  &__slideshow {
    &--sp {
      @extend .inspection__slideshow;
      margin-top: 60px;
    }
    &--pc {
      @extend .inspection__slideshow;
      width: 100vw;
      height: 22.7485vw;
      margin-top: 185px;
    }
  }
}
</style>
